import loginApi from "../services/loginApi";
let api = new loginApi();

// export function* removeCookie({ payload, resolve }) {
//     try {
//         let { data } = yield api.removeCookie(payload)
//         if (data.success) {            
//             resolve && resolve()
//             localStorage.removeItem('sessionExpired');
//             localStorage.removeItem('triggered401');    
//             window.location.href = '/mutualfund/login';
//         }
//         else {
//             resolve && resolve()            
//         }
//     } catch (error) {
        
//     }
// }

export function* removeCookie({ payload, resolve }) {
    try {
        let { data } = yield api.removeCookie(payload);

        if (data && data.success) {            
            resolve && resolve()
        }
        else {
            resolve && resolve()            
        }
    } catch (error) {
        resolve && resolve(); // Resolve the promise even in case of an error
    }
}
