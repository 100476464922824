import { takeLatest, put } from 'redux-saga/effects';
import { ABOUTUS_DATA_PENDING, ABOUTUS_DATA_SUCCESS, ABOUTUS_DATA_FAILURE } from '../redux/types/preloginType';
import Api from '../services/preLoginApi';

let allApi = new Api();

function* fetchData() {
    let payload = {
        "api_name": "Aboutus"
    };
    let apiresponse = yield allApi.aboutUs(payload)
    yield put({ type: ABOUTUS_DATA_SUCCESS, payload: apiresponse.data.data })
}

export function* aboutUs() {
    yield takeLatest(ABOUTUS_DATA_PENDING, fetchData)
}