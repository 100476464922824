import { publicKey } from '../../lib/crypto/key'
import NodeRSA from 'node-rsa';

export const registerAesKey = () => {

    let randomBadAss = Math.floor(1000000000000000 + Math.random() * 9000000000000000).toString();
    let badAssObject = { badAssGuy: randomBadAss };

    const encrypt = new NodeRSA(publicKey); // set public key
    let encrypted = encrypt.encrypt(randomBadAss, 'base64'); // encrypt AES Key
    badAssObject.badAssSecure = encrypted; // add encrypted to AES key object


    window.badAssGuys = badAssObject;

}