import { createStore, applyMiddleware, compose } from "redux";
import reducers from "./reducers";
import { createLogger } from "redux-logger";
import createSagaMiddleware from 'redux-saga'
import rootSaga from "../sagas";
import { routerMiddleware } from "connected-react-router";
import history from "../lib/history";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const persistConfig = {
  key: 'root',
  storage,
  blacklist:[''],
  whitelist:['Auth'],
}


const sagaMiddleware = createSagaMiddleware()

// add logger in middleware if wanted to be logged
const logger = createLogger({
  predicate: (getsState, action) => !!process.env.NODE_ENV
});
//console.log(logger().predicate,'predicate')

const connectedRouterMiddleware = routerMiddleware(history);
const persistedReducer = persistReducer(persistConfig, reducers)
const initialstore={};
let middlewares = [
  sagaMiddleware,
];
  if (!process.env.IS_SERVER) {
     middlewares.push(connectedRouterMiddleware);
  }
const enhancers = [composeWithDevTools(applyMiddleware(...middlewares))];
const store=createStore(persistedReducer,
  initialstore,
  compose(
  ...enhancers
));
sagaMiddleware.run(rootSaga);
store.asyncReducers = {};
const persistor =persistStore(store);

export  {store,persistor};


// export default function configureStore(initialState = {}) {
//   // Create the store with two middlewares
//   let middlewares = [
//     logger,
//     sagaMiddleware,
//   ];

//   if (!process.env.IS_SERVER) {
//     middlewares.push(connectedRouterMiddleware);
//   }

//   const enhancers = [composeWithDevTools(applyMiddleware(...middlewares))];
//   //const enhancers = [(applyMiddleware(...middlewares))];

//   if (!process.env.IS_SERVER && process.env.REACT_APP_MODE === "development") {
//     if (window && window.__REDUX_DEVTOOLS_EXTENSION__) {
//       enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__())
//     }
//   }

//   const store = createStore(
//     reducers,
//     initialState,
//     compose(
//       ...enhancers
//     )
//   );

//   sagaMiddleware.run(rootSaga);
//   // Extensions
//   //store.runSaga = sagaMiddleware.run
//   store.asyncReducers = {}; // Async reducer registry

//   return store;
// }
