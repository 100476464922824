import { ADDLOGINMODE, INIT_APP_RESPONSE, INIT_GET_FOLIO_LIST_RESPONSE } from '../types/init';

const initialState = {
    loginModeOptions: ["Loading options"],
    folioList: null,
    schemeList: null
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case ADDLOGINMODE:
            return { ...state, ...action.payload }
        case INIT_APP_RESPONSE:
            const { loginModeOptions, credentialModeOptions } = action.payload;
            console.log(action.payload, 'initReducer')
            return { ...state, loginModeOptions, credentialModeOptions }
        case INIT_GET_FOLIO_LIST_RESPONSE:
            return { ...state, folioList: action.payload.folioList, schemeList: action.payload.schemeList }
        default:
            return state
    }

}
