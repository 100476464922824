import * as type from '../../types/trans';
import {AUTHMOH,AUTHFOLIO, AUTHRESP,TYPE_LOGIN} from '../../types/onboarding';

let init = {
    cvlData: null,
    guestMenu: null,
    onboardAuth:null,
    onboardFolio:null,
    arnDetails: null,
    transPAN: null,
    loginType:true,
    hideInvestedfund:false,  
    activeListContainerData:null
}

export default function authDetails(state = init, action) {
    switch (action.type) {
        case type.SAVE_CVL_DATA:
            return {
                ...state,
                cvlData: action.payload
            }
        
         case AUTHMOH:
             return{
                ...state,
                onboardAuth: action.payload
             } 
         case AUTHFOLIO:
            return{
                ...state,
                onboardFolio: action.payload
            } 
            
        case AUTHRESP:
            return{
                ...state,
                arnDetails: action.payload
            }
        case type.INVESTER_PAN_ADD:
            return{
                ...state,
                transPAN:action.payload
            }  
        case TYPE_LOGIN:
            return {
                ...state,
            loginType:action.payload   
            }       
        case type.HIDE_INVESTED_FUND:
            return {
                ...state,
                hideInvestedfund: action.payload 
            }       
        case type.ACTIVE_LIST_CONTAINER_DATA:  
            return {
                ...state,
                activeListContainerData : action.payload 
            } 
        default:
            return state
    }
}