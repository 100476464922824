import {
    CLIENT_EXIST_DATA_RESPONSE, CLIENT_EXIST_DATA_FAILURE
    , CLIENT_MF_DATA_RESPONSE, CLIENT_MF_DATA_FAILURE
    , GetMFAccountStatement_DATA_RESPONSE, GetMFAccountStatement_DATA_FAILURE
    , CapitalGainLoss_DATA_RESPONSE, CapitalGainLoss_DATA_FAILURE
    , SIPDashboard_DATA_RESPONSE, SIPDashboard_DATA_FAILURE, REPORT_SEND_RESPONSE, REPORT_SEND_FAILURE, BITALY_LINK_RESPONSE, BITALY_LINK_FAILURE
} from "../types/reports";

const initialState = {
    clientExist: {},
    clientMF: {},
    GetMFAccountStatement: {},
    CapitalGainLoss: {},
    SIPDashboard: {},
    Bitaly_link: {},
    ReportSend: {}
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case CLIENT_EXIST_DATA_RESPONSE:
            return { ...state, clientExist: action.payload }
        case CLIENT_EXIST_DATA_FAILURE:
            return { ...state, clientExist: action.payload }

        case CLIENT_MF_DATA_RESPONSE:
            return { ...state, clientMF: action.payload }
        case CLIENT_MF_DATA_FAILURE:
            return { ...state, clientMF: action.payload }

        case GetMFAccountStatement_DATA_RESPONSE:
            return { ...state, GetMFAccountStatement: action.payload }
        case GetMFAccountStatement_DATA_FAILURE:
            return { ...state, GetMFAccountStatement: action.payload }

        case CapitalGainLoss_DATA_RESPONSE:
            return { ...state, CapitalGainLoss: action.payload }
        case CapitalGainLoss_DATA_FAILURE:
            return { ...state, CapitalGainLoss: action.payload }

        case SIPDashboard_DATA_RESPONSE:
            return { ...state, SIPDashboard: action.payload }
        case SIPDashboard_DATA_FAILURE:
            return { ...state, SIPDashboard: action.payload }

        case REPORT_SEND_RESPONSE:
            return { ...state, ReportSend: action.payload }
        case REPORT_SEND_FAILURE:
            return { ...state, ReportSend: action.payload }

        case BITALY_LINK_RESPONSE:
            return { ...state, Bitaly_link: action.payload }
        case BITALY_LINK_FAILURE:
            return { ...state, Bitaly_link: action.payload }

        default:
            return state
    }

}