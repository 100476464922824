import { takeLatest, put, call, delay } from 'redux-saga/effects';
import * as type from '../../redux/types/trans';
import Api from '../../services/trans/trans';
import Dummy from '../../components/organism/transaction/portfolio.json';
import { push } from 'connected-react-router';
let allApi = new Api();

async function fetchUser(data) {
    let res = await allApi.common(data);
    return res;

}
async function folioList(data) {
    let res = await allApi.common(data);
    return res;

}

function* fetchData(action) {
    let getMOH = action.payload;
    localStorage.setItem("panEntered", getMOH.panNo);
    let payload = {
        "apiName": "dashboard_DIT",
        "body": {
            "displayZeroFolio": "Y",
            "groupBy": "Scheme",
            ...getMOH
        }
    }
    // "apiName": "GetFolioList_DIT",
    // 		"body": {panNo: pan}
    let foliolistpayload = {
        "apiName": "GetFolioList_DIT",
        "body": { panNo: getMOH.panNo }
    };
    let apiresponseFolio = yield call(folioList, foliolistpayload);
    if (apiresponseFolio.data.success) {
        let arrayCheck = apiresponseFolio.data.data;
        yield put({ type: type.ZBF_FOLIO_SUCCESS, payload: arrayCheck })
    } else {
        yield put({ type: type.ZBF_FOLIO_FAIL, payload: [] })

    }

    if (apiresponseFolio.hasOwnProperty('code') && apiresponseFolio.code === 401) {
        yield put(push('/investonline/dist'));
        window.location.href = "/";
    }


    let apiresponse = yield call(fetchUser, payload)
    let indexFolioSchemeCode = {};
    let indexSchemename = {};
    let folioData = [] // unique folio number
    let schemeCode = [];// unique schemecode
    let MOHType = []; // unique Hoilding Type
    let portfoliodropDown = [];// dropdown uniqueScheme
    let MOHwiseFolio = {};
    let prefillDropDown = [];
    let getuniqueschemeCode = {};
    let foliowiseholdingtemp = {}
    let folioWiseData = {};
    let folioholdingType = {};
    let portfolioData = null;
    let schemeData = {}; //singleschemeData

    //apiresponse = Dummy;
    if (apiresponse.data.success) {
        let getData = apiresponse.data.data.portfolios;
        let getallData = apiresponse.data.data;
        portfolioData = getData;
        getData.map((curr, index) => {

            indexFolioSchemeCode[`${curr.folioNo}_${curr.schemeCode}`] = curr;
            // indexSchemename[`${curr.folioNo}_${curr.schemeCode}`] = curr.schemeName;
            if (folioData.indexOf(curr.folioNo) === -1) {
                folioData.push(curr.folioNo);
            }
            if (MOHType.indexOf(curr.holdingMode) === -1) {
                MOHType.push(curr.holdingMode);
            }
            if (schemeCode.indexOf(curr.schemeCode) === -1) {

                portfoliodropDown.push({ "label": curr.schemeName, "value": curr.schemeCode })
                indexSchemename[`${curr.folioNo}_${curr.schemeCode}`] = curr.schemeName;
                schemeCode.push(curr.schemeCode);
            }

        })

        // folio type
        folioData.map((curr, index) => {
            let objdummy = []
            let folioWiseholdingType = [];
            getData.forEach((element) => {
                if (curr === element.folioNo) {
                    objdummy.push(element)
                    if (folioWiseholdingType.indexOf(element.holdingMode) == -1) {
                        folioWiseholdingType.push(element.holdingMode);
                        foliowiseholdingtemp[element.folioNo] = element.holdingMode;
                    }
                }

            })

            folioWiseData[curr] = objdummy
            folioholdingType[curr] = folioWiseholdingType

        })


        // schemeCodeWide folio
        schemeCode.map((curr, index) => {
            let schemeArray = [];
            let MOHtype = [];
            let dublicatScheme = [];
            getData.forEach((element) => {
                if (curr === element.schemeCode) {
                    dublicatScheme.push(element);
                    if (schemeArray.indexOf(element.folioNo) == -1) {
                        schemeArray.push(element.folioNo)
                    }
                    if (MOHtype.indexOf(element.holdingMode) == -1) {
                        MOHtype.push(element.holdingMode)
                    }
                }

            })
            schemeData[curr] = dublicatScheme
            getuniqueschemeCode[curr] = schemeArray
            MOHwiseFolio[curr] = MOHType


        })
        if (Object.keys(indexSchemename).length > 0) {
            for (const [key, value] of Object.entries(indexSchemename)) {
                prefillDropDown.push({ "label": value, "value": key })
            }
        }

        // yield put({ type: type.PORTFOLIO_DATA_SUCCESS, payload: {portfolioAll:getallData, dropdown: prefillDropDown, indexScheme: indexFolioSchemeCode, portfolioData: portfolioData, foliowiseScheme: folioholdingType, schemeWiseFolio: getuniqueschemeCode } })
        yield put({ type: type.PORTFOLIO_DATA_SUCCESS, payload: { portfolioAll: getallData, dropdown: portfoliodropDown, indexScheme: indexFolioSchemeCode, portfolioData: portfolioData, foliowiseScheme: folioholdingType, schemeWiseFolio: getuniqueschemeCode, schemeData: schemeData } })

    } else {
        yield put({ type: type.PORTFOLIO_DATA_FAIL, payload: { portfolioAll: null, dropdown: prefillDropDown, indexScheme: indexFolioSchemeCode, portfolioData: portfolioData } })
    }



    //yield put({ type: NAV_SUCCESS, payload: })
}

export function* portFolioCall() {
    yield takeLatest(type.PORTFOLIO_DATA_PENDING, fetchData)
}

export function* addToCart(data) {

    yield takeLatest(type.ADD_TO_CART_PENDING, callCartAPI)
}

function* callCartAPI(data) {
    let result = yield allApi.common(data.payload);
    if (result.data.success) {
        yield put({ type: type.ADD_TO_CART_SUCCESS, payload: data.body })
    } else {
        yield put({ type: type.ADD_TO_CART_FAILURE, payload: data.body })
    }
}

export function* singleCartData(data) {
    yield put({ type: type.SINGLE_CART_DATA, payload: data })
}

export function* getSipDashboard(data) {
    yield takeLatest(type.SIPDASHBOARD_PENDING, callSipDashboard)
}

function* callSipDashboard(data) {
    let result = yield allApi.common(data.payload);
    if (typeof result.data === "object") {
        if (result.data.success) {
            if (result.data.data.status === "200") {
                // let schPay = {
                //     "api_name": "GetSchemeDetForSIPDashboard",
                //     "data": result.data.data.data
                // }                
                // let resultSch = yield allApi.getSIPScheme(schPay);
                yield delay(100)
                let response = collectHoldingMode(result.data, "SIP");
                yield put({ type: type.SIPDASHBOARD_SUCCESS, sipDashboardStatus: true, payload: result.data.data, holdingMode: response.holdingMode, processMode: response.processMode, transStatus: response.transStatus, statementData: result.data.data.scheduleViewSIP })
            } else {
                yield put({ type: type.SIPDASHBOARD_FAILURE, sipDashboardStatus: false, payload: result.data.data })
            }
        } else {
            yield put({ type: type.SIPDASHBOARD_FAILURE, sipDashboardStatus: false, payload: result.data.message })
        }
    } else {
        if (result.hasOwnProperty('code') && result.code === 401) {
            yield put(push('/investonline/dist'));
            yield put({ type: type.SIPDASHBOARD_FAILURE, sipDashboardStatus: false, payload: "Logout" })
        } if (result.hasOwnProperty('code') && result.code === 400) {
            yield put({ type: type.SIPDASHBOARD_FAILURE, swpDashboardStatus: false, payload: result.data.message })
        } else {
            yield put({ type: type.SIPDASHBOARD_FAILURE, sipDashboardStatus: false, payload: result.data.message })
        }

    }
}


function* folioDescCall() {
    let payload = {
        "apiName": "GetFolioDescription",
        "body": {}
    };
    let customData = {};
    let apires = yield allApi.common(payload);
    if (apires.data.success) {
        let getResult = apires.data.data;
        if (Object.keys(getResult).length > 0) {
            let foliod = getResult.folioDescs;
            if (foliod.length > 0) {
                foliod.map((curr, index) => {
                    customData[curr.folioNo] = curr;
                })
            }
        }
        yield put({ type: type.FOLIODESC_SUCCESS, payload: customData })
    } else {
        yield put({ type: type.FOLIODESC_FAIL, payload: customData })
    }



}




export function* FolioDescription(data) {
    yield takeLatest(type.FOLIODESC_PENDING, folioDescCall)
}



export function* getSwpDashboard(data) {
    yield takeLatest(type.SWPDASHBOARD_PENDING, callSwpDashboard)
}

function* callSwpDashboard(data) {
    let result = yield allApi.common(data.payload);
    if (typeof result.data === "object") {
        if (result.data.success) {
            if (result.data.data.status === "200") {
                yield delay(100)
                let response = collectHoldingMode(result.data, "SWP");
                yield put({ type: type.SWPDASHBOARD_SUCCESS, swpDashboardStatus: true, payload: result.data.data, holdingMode: response.holdingMode, processMode: response.processMode, transStatus: response.transStatus })
            } else {
                yield put({ type: type.SWPDASHBOARD_FAILURE, swpDashboardStatus: false, payload: result.data.data })
            }
        } else {
            yield put({ type: type.SWPDASHBOARD_FAILURE, swpDashboardStatus: false, payload: result.data.message })
        }
    } else {
        if (result.hasOwnProperty('code') && result.code === 401) {
            yield put(push('/investonline/dist'));
            yield put({ type: type.SWPDASHBOARD_FAILURE, swpDashboardStatus: false, payload: "Logout" })
        } else if (result.hasOwnProperty('code') && result.code === 400) {
            yield put({ type: type.SWPDASHBOARD_FAILURE, swpDashboardStatus: false, payload: result.data.message })
        } else {
            yield put({ type: type.SWPDASHBOARD_FAILURE, swpDashboardStatus: false, payload: result.data.message })
        }
    }
}

export function* getStpDashboard(data) {
    yield takeLatest(type.STPDASHBOARD_PENDING, callStpDashboard)
}

function* callStpDashboard(data) {
    let result = yield allApi.common(data.payload);
    if (typeof result.data === "object") {
        if (result.data.success) {
            if (result.data.data.status === "200") {
                yield delay(100)
                let response = collectHoldingMode(result.data, "SWP");
                yield put({ type: type.STPDASHBOARD_SUCCESS, stpDashboardStatus: true, payload: result.data.data, holdingMode: response.holdingMode, processMode: response.processMode, transStatus: response.transStatus })
            } else {
                yield put({ type: type.STPDASHBOARD_FAILURE, stpDashboardStatus: false, payload: result.data.data })
            }
        } else {
            // alert()
            // yield put({ type: type.STPDASHBOARD_FAILURE, stpDashboardStatus: false, payload: result.data.message })
            if (result.hasOwnProperty('code') && result.code === 401) {
                yield put(push('/investonline/dist'));
                yield put({ type: type.STPDASHBOARD_SUCCESS, stpDashboardStatus: false, payload: "Logout" })
            } else if (result.hasOwnProperty('code') && result.code === 400) {
                yield put({ type: type.STPDASHBOARD_SUCCESS, swpDashboardStatus: false, payload: "NO DATA FOUND" })
            } else {
                yield put({ type: type.STPDASHBOARD_SUCCESS, stpDashboardStatus: false, payload: result.data.message })
            }
        }
    } else {
        if (result.hasOwnProperty('code') && result.code === 401) {
            yield put(push('/investonline/dist'));
            yield put({ type: type.STPDASHBOARD_SUCCESS, stpDashboardStatus: false, payload: "Logout" })
        } else if (result.hasOwnProperty('code') && result.code === 400) {
            yield put({ type: type.STPDASHBOARD_SUCCESS, swpDashboardStatus: false, payload: result.data.message })
        } else {
            yield put({ type: type.STPDASHBOARD_SUCCESS, stpDashboardStatus: false, payload: result.data.message })
        }
    }
}

function collectHoldingMode(data, type) {
    let holdingMode = [];
    let processMode = [];
    let transStatus = [];
    if (type === "SIP") {
        if (data.success) {
            if (data.data.length > 0) {
                data.data.map((curr, ind) => {
                    if (holdingMode.indexOf(curr.sm_Mode) === -1) {
                        holdingMode.push(curr.sm_Mode);
                    }
                    if (processMode.indexOf(curr.sM_Onlineoffline) === -1) {
                        processMode.push(curr.sM_Onlineoffline)
                    }
                    if (transStatus.indexOf(curr.sm_status) === -1) {
                        transStatus.push(curr.sm_status)
                    }
                })
            }
        }
    } else {
        if (data.success) {
            if (data.data.data.length > 0) {
                data.data.data.map((curr, ind) => {
                    if (holdingMode.indexOf(curr.modeofHolding) === -1) {
                        holdingMode.push(curr.modeofHolding);
                    }
                    if (processMode.indexOf(curr.transactionMode) === -1) {
                        processMode.push(curr.transactionMode)
                    }
                    if (transStatus.indexOf(curr.stpswpStatus) === -1) {
                        transStatus.push(curr.stpswpStatus)
                    }
                })
            }
        }
    }

    return { holdingMode: holdingMode, processMode: processMode, transStatus: transStatus };
}


function createDataSet() {
    // modeofHolding
}

export function* getAllFolios() {
    yield takeLatest(type.GET_ALL_FOLIOS, callZBFFolios);
}

function* callZBFFolios(data) {
    let foliolistpayload = {
        "apiName": "GetFolioList_DIT",
        "body": { panNo: data.payload }
    };
    let apiresponseFolio = yield call(folioList, foliolistpayload);
    if (apiresponseFolio.data.success) {
        let arrayCheck = apiresponseFolio.data.data;
        yield put({ type: type.ZBF_FOLIO_SUCCESS, payload: arrayCheck })
    } else {
        yield put({ type: type.ZBF_FOLIO_FAIL, payload: [] })

    }
}

async function getSchemeMaster(data) {
    let response = await allApi.commonGet(data);
    return response;
}

function* getSchemeDetails(action) {
    let payload = action.payload;
    let apiresponse = yield call(getSchemeMaster, payload)//allApi.commonGet(payload);;
    if (apiresponse?.data?.success) {
        yield put({ type: type.SCHEME_MASTER_DETAILS_SUCCESS, payload: apiresponse?.data?.data?.schemeList });
    } else {
        yield put({ type: type.SCHEME_MASTER_DETAILS_FAILURE, payload: [] });
    }
}

export function* getSchemeMasterDetails() {
    yield takeLatest(type.SCHEME_MASTER_DETAILS_PENDING, getSchemeDetails);
}



