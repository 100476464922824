import { takeLatest, put, call } from 'redux-saga/effects';
import { NAV_SUCCESS, NAV_FAILURE, NAV_PENDING } from '../redux/types/preloginType';
import Api from '../services/preLoginApi';
let allApi = new Api();

async function fetchUser(data) {
    let res = await allApi.NavCall(data);
    return res;

}

function* fetchData() {
    let payload = {
        "api_name": "NAVDetails"
    }
    let apiresponse = yield call(fetchUser, payload)
    if (apiresponse.data.success) {
        yield put({ type: NAV_SUCCESS, payload: apiresponse.data.data })
    } else {
        yield put({ type: NAV_FAILURE, payload: [] })
    }



    //yield put({ type: NAV_SUCCESS, payload: })
}

export function* NavApi() {

    yield takeLatest(NAV_PENDING, fetchData)
}