import { put } from 'redux-saga/effects';
import reportsApi from "../services/reports";
import {
    CLIENT_EXIST_DATA_RESPONSE, CLIENT_EXIST_DATA_FAILURE
    , CLIENT_MF_DATA_RESPONSE, CLIENT_MF_DATA_FAILURE
    , GetMFAccountStatement_DATA_RESPONSE, GetMFAccountStatement_DATA_FAILURE
    , CapitalGainLoss_DATA_RESPONSE, CapitalGainLoss_DATA_FAILURE
    , SIPDashboard_DATA_RESPONSE, SIPDashboard_DATA_FAILURE, REPORT_SEND_RESPONSE, REPORT_SEND_FAILURE, BITALY_LINK_RESPONSE, BITALY_LINK_FAILURE
} from '../redux/types/reports';

let api = new reportsApi();

export function* checkClientExist({ payload, resolve }) {
    let responsePayload = {};
    try {
        let { data } = yield api.CheckMFPanDist(payload);
        responsePayload = data;
        if (data.success) {
            yield put({ type: CLIENT_EXIST_DATA_RESPONSE, payload: responsePayload, })
            resolve && resolve(data)

        } else {
            yield put({ type: CLIENT_EXIST_DATA_FAILURE, payload: responsePayload })
            resolve && resolve(data)

        }
    } catch (e) {
        yield put({ type: CLIENT_EXIST_DATA_FAILURE, payload: responsePayload })
        resolve && resolve(responsePayload)

    }
}

export function* ClientMF({ payload, resolve }) {
    let responsePayload = {};
    try {
        let { data } = yield api.ClientMF(payload);
        responsePayload = data;
        if (data.success) {
            yield put({ type: CLIENT_MF_DATA_RESPONSE, payload: responsePayload, })
            resolve && resolve(data)

        } else {
            yield put({ type: CLIENT_MF_DATA_FAILURE, payload: responsePayload })
            resolve && resolve(data)

        }
    } catch (e) {
        yield put({ type: CLIENT_MF_DATA_FAILURE, payload: responsePayload })
        resolve && resolve(responsePayload)

    }
}

export function* GetMFAccountStatement({ payload, resolve }) {
    let responsePayload = {};
    try {
        let { data } = yield api.GetMFAccountStatement(payload);
        responsePayload = data;
        if (data.success) {
            yield put({ type: GetMFAccountStatement_DATA_RESPONSE, payload: responsePayload, })
            resolve && resolve(data)

        } else {
            yield put({ type: GetMFAccountStatement_DATA_FAILURE, payload: responsePayload })
            resolve && resolve(data)

        }
    } catch (e) {
        yield put({ type: GetMFAccountStatement_DATA_FAILURE, payload: responsePayload })
        resolve && resolve(responsePayload)

    }
}

export function* CapitalGainLoss({ payload, resolve }) {
    let responsePayload = {};
    try {
        let { data } = yield api.CapitalGainLoss(payload);
        responsePayload = data;
        if (data.success) {
            yield put({ type: CapitalGainLoss_DATA_RESPONSE, payload: responsePayload, })
            resolve && resolve(data)

        } else {
            yield put({ type: CapitalGainLoss_DATA_FAILURE, payload: responsePayload })
            resolve && resolve(data)

        }
    } catch (e) {
        yield put({ type: CapitalGainLoss_DATA_FAILURE, payload: responsePayload })
        resolve && resolve(responsePayload)

    }
}

export function* SIPDashboard({ payload, resolve }) {
    let responsePayload = {};
    try {
        let { data } = yield api.SIPDashboard(payload);
        responsePayload = data;
        if (data.success) {
            yield put({ type: SIPDashboard_DATA_RESPONSE, payload: responsePayload, })
            resolve && resolve(data)

        } else {
            yield put({ type: SIPDashboard_DATA_FAILURE, payload: responsePayload })
            resolve && resolve(data)

        }
    } catch (e) {
        yield put({ type: SIPDashboard_DATA_FAILURE, payload: responsePayload })
        resolve && resolve(responsePayload)

    }
}

export function* ReportSend({ payload, resolve }) {
    let responsePayload = {};
    try {
        let { data } = yield api.Report_Send(payload);
        responsePayload = data;
        if (data.success) {
            yield put({ type: REPORT_SEND_RESPONSE, payload: responsePayload, })
            resolve && resolve(data)

        } else {
            yield put({ type: REPORT_SEND_FAILURE, payload: responsePayload })
            resolve && resolve(data)

        }
    } catch (e) {
        yield put({ type: REPORT_SEND_FAILURE, payload: responsePayload })
        resolve && resolve(responsePayload)

    }
}

export function* Bitaly_link({ payload, resolve }) {
    let responsePayload = {};
    try {
        let { data } = yield api.Bitaly_Link(payload);
        responsePayload = data;
        if (data.success) {
            yield put({ type: BITALY_LINK_RESPONSE, payload: responsePayload, })
            resolve && resolve(data)

        } else {
            yield put({ type: BITALY_LINK_FAILURE, payload: responsePayload })
            resolve && resolve(data)

        }
    } catch (e) {
        yield put({ type: BITALY_LINK_FAILURE, payload: responsePayload })
        resolve && resolve(responsePayload)

    }
}


