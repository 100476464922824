import { put } from 'redux-saga/effects'
import appApi from "../services/appApi";
import { INIT_APP_RESPONSE, INIT_APP_RESPONSE_FAILED } from '../redux/types/init';
import { START_LOADING_RESPONSE, FINISH_LOADING_RESPONSE } from '../redux/types/loader'


let api = new appApi();

export function* initApp({ type, payload, resolve }) {
    let responsePayload = {};
    let responsePayloadLoader = { start: true, stop: false };
    try {
        yield put({ type: START_LOADING_RESPONSE, payload: responsePayloadLoader })
        let { data } = yield api.init(payload);
        responsePayloadLoader = { start: false, stop: true };
        if (data.success) {
            yield put({ type: FINISH_LOADING_RESPONSE, payload: responsePayloadLoader })

            responsePayload.loginModeOptions = formatLoginModeOptions(data.data.objLoginModes.loginMode)
            responsePayload.credentialModeOptions = formatCredentialModeOptions(data.data.objLoginModes.credentialMode)
            yield put({ type: INIT_APP_RESPONSE, payload: responsePayload })
            resolve && resolve(data.data)
        }
        else {
            yield put({ type: FINISH_LOADING_RESPONSE, payload: responsePayloadLoader })

            yield put({ type: INIT_APP_RESPONSE_FAILED })
            resolve && resolve(data.data)
        }
    } catch (e) {
        yield put({ type: FINISH_LOADING_RESPONSE, payload: responsePayloadLoader })

        yield put({ type: INIT_APP_RESPONSE_FAILED })
    }
}

function formatLoginModeOptions(data) {
    let sortOptions = data;
    let options = sortOptions.map((option) => {
        return { label: option.textValue, value: option.id };
    })

    options.shift(); // remove first element select which is not needed.

    return options;
}

function formatCredentialModeOptions(data) {
    let sortOptions = data;
    let options = sortOptions.map((option) => {
        return { label: option.textValue, value: option.id };
    })

    options.shift(); // remove first element select which is not needed.

    return options;
}